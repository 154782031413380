// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Highcharts from 'highcharts';
require("highcharts/modules/data")(Highcharts)
require("highcharts/modules/drilldown")(Highcharts)
// require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/map")(Highcharts)
window.Highcharts = Highcharts;
// window.jQuery = window.$ = require('jquery')
// require('datatables.net')
// require('datatables.net-bs4')
// require('datatables.net-scroller-bs4')


Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "jquery"
import "jquery-ui"
import "./custom"
import "./chart"
import  "./jsencrypt.min"

// import "./jquery.min"
// import "./jquery-ui"
// import "./jquery.dataTables.min"

import "bootstrap"
import "./black-dashboard.min"
// import 'semantic-ui-sass';
