
$(document).ready(function() {
    $('#camp_month')
        .dropdown()
    ;
    $('#sidebtn').click(function () {
        $(' body').toggleClass('sidebar-mini');
        $(this).toggleClass('right')
    })
    $(".openbtn").on("click", function() {
        $(".ui.sidebar").toggleClass("transition");
        $(".ui.sidebar").toggleClass("very thin icon");
        $(".xy ").toggleClass("z");
        $(".sidebar z").toggleClass("displaynone");
        $(".ui.accordion").toggleClass("displaynone");
        $(".ui.dropdown.item").toggleClass("displayblock");
        $("#hitem").removeClass("displayblock");
        $(".xy").find('img').toggle();
        $("#sec").toggleClass("con");
    })
    $(".ui.dropdown").dropdown({
        allowCategorySelection: true,
        transition: "fade up",
        context: 'sidebar',
        on: "hover"
    });

    $('.ui.accordion').accordion({
        selector: {

        }
    });
    $('.ui.sticky')
        .sticky()
    ;

    $('.card-value').each(function() {
        var fontSize = 20;
        var x = $(this).text().length
        console.log(x);
        if (x > 5)
            fontSize -= x-8
            $(this).css("font-size", fontSize );
    });
});