function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function thousandsp(){
    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    })
}
window.map = function map(id,state,district,title,height) {
    thousandsp();

    /*- Check data labels after drilling. Label rank? New positions?*/
    Highcharts.getJSON('/rajasthan_district.json', function (geojson) {
        let data = Highcharts.geojson(geojson);
        // let data = geojson
        // Set drilldown pointers
        data.forEach((d, i) => {
            d.drilldown = d.properties['district'];
            // d.value = i; // Non-random bogus data
        });
        function getScript(url, cb) {
            const script = document.createElement('script');
            script.src = url;
            script.onload = cb;
            document.head.appendChild(script);
        }
// Instantiate the map
        Highcharts.mapChart(id, {
            chart: {
                height: height,
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            const chart = this,
                                mapKey = 'countries/us/' + e.point.drilldown + '-all';
                            // Handle error, the timeout is cleared on success
                            let fail = setTimeout(() => {
                                if (!Highcharts.maps[mapKey]) {
                                    chart.showLoading(alert(e.point.drilldown+' map is inactive'));
                                    fail = setTimeout(() => {
                                        chart.hideLoading();
                                    }, 1000);
                                }
                            }, 1000);
                            // Show the spinner
                            chart.showLoading('loading'); // Font Awesome spinner
                            // Load the drilldown map
                            Highcharts.getJSON('/'+e.point.drilldown+'_blocks.json', function (ujson) {
                                data = Highcharts.geojson(ujson);
                                 console.log(e.point.drilldown)
                                // // Set a non-random bogus value
                                // data.forEach((d, i) => {
                                //     d.value = i;
                                // });
                                // Hide loading and add series
                                chart.hideLoading();
                                clearTimeout(fail);
                                chart.addSeriesAsDrilldown(e.point, {
                                    name: e.point.name,
                                    data: district,
                                    mapData: data,
                                    keys: ['name', 'value'],
                                    joinBy: 'name',
                                    name: title,
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.name}'
                                    }
                                });
                            });
                        }

                        this.setTitle( { text: e.point.drilldown});
                    },
                    drillup: function () {
                        this.setTitle( { text: 'Rajasthan' });
                    }
                }

            },
            lang: {
                drillUpText: 'Back to Rajasthan'
            },
            title: {
                text: 'Rajasthan'
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: capitalizeFirstLetter(title),
                floating: true,
                align: 'right',
                y: 50,
                style: {
                    fontSize: '16px'
                }
            },

                colorAxis: {
                    min: 0,
                    minColor: '#deb7b4',
                    maxColor: '#ca3f32'

                },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },

            plotOptions: {
                map: {
                    states: {
                        hover: {
                            color: '#d63722'
                        }
                    }
                }
            },

            series: [{
                data: state,
                mapData: data,
                keys: ['drilldown', 'value'],
                joinBy: 'drilldown',
                name: capitalizeFirstLetter(title),
                minSize: 80,
                states: {
                    hover: {
                        color: 'red'
                    }
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.properties.district}'
                }
                // }, {
                //     type: 'mapline',
                //     data: separators,
                //     color: 'silver',
                //     enableMouseTracking: false,
                //     animation: {
                //         duration: 500
                //     }
            }],

            drilldown: {
                activeDataLabelStyle: {
                    color: '#FFFFFF',
                    textDecoration: 'none',
                    textOutline: '1px #000000'
                },
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        x: 0,
                        y: 60
                    }
                }
            }
        });

    })

    //
    // Highcharts.getJSON('/rajasthan_district.json', function (geojson) {
    //
    //         // Initiate the chart
    //         Highcharts.mapChart('homemap', {
    //             chart: {
    //                 height: 520,
    //                 map: geojson,
    //                 events: {
    //                     drilldown: function (e) {
    //                         if (!e.seriesOptions) {
    //                             const chart = this,
    //                                 mapKey = 'countries/us/' + e.point.drilldown + '-all';
    //
    //                             // Handle error, the timeout is cleared on success
    //                             let fail = setTimeout(() => {
    //                                 if (!Highcharts.maps[mapKey]) {
    //                                     chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
    //                                     fail = setTimeout(() => {
    //                                         chart.hideLoading();
    //                                     }, 1000);
    //                                 }
    //                             }, 3000);
    //
    //                             // Show the spinner
    //                             chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner
    //
    //                             // Load the drilldown map
    //                             getScript('https://code.highcharts.com/mapdata/' + mapKey + '.js', () => {
    //                                 data = Highcharts.geojson(Highcharts.maps[mapKey]);
    //
    //                                 // Set a non-random bogus value
    //                                 data.forEach((d, i) => {
    //                                     d.value = i;
    //                                 });
    //
    //                                 // Hide loading and add series
    //                                 chart.hideLoading();
    //                                 clearTimeout(fail);
    //                                 chart.addSeriesAsDrilldown(e.point, {
    //                                     name: e.point.name,
    //                                     data: data,
    //                                     dataLabels: {
    //                                         enabled: true,
    //                                         format: '{point.name}'
    //                                     }
    //                                 });
    //                             });
    //                         }
    //
    //                         this.setTitle(null, { text: e.point.name });
    //                     },
    //                     drillup: function () {
    //                         this.setTitle(null, { text: '' });
    //                     }
    //                 }
    //
    //             },
    //
    //             title: {
    //                 text: 'Rajasthan Map'
    //             },
    //             credits: {
    //                 enabled: false
    //             },
    //             mapNavigation: {
    //                 enabled: true,
    //                 buttonOptions: {
    //                     verticalAlign: 'bottom'
    //                 }
    //             },
    //             tooltip: {
    //                 formatter: function () {
    //                     return '<b>' + this.series.name + '</b><br>' +
    //                         '' + this.point.properties['district'] +
    //                         ':' + this.point.value;
    //                 }
    //             },
    //
    //             colorAxis: {
    //                 min: 0,
    //                 minColor: '#deb7b4',
    //                 maxColor: '#ca3f32'
    //
    //             },
    //             series: [{
    //                 data: data,
    //                 keys: ['district', 'value'],
    //                 joinBy: 'district',
    //                 name: 'Positive',
    //                 minSize: 200,
    //                 states: {
    //                     hover: {
    //                         color: 'red'
    //                     }
    //                 },
    //                 dataLabels: {
    //                     enabled: true,
    //                     formatter: function () {
    //                         return this.point.properties['district'];
    //                     },
    //                 }
    //             }]
    //         });
    //     }
    // );
}


window.population_pyramid = function population_pyramid(male,female){
    var categories = [
        '0-4','5-10','11-15','16-18',
        '19-21', '22-25', '26-29', '30-34', '35-39',
        '40-44','45-49', '50-59', '60-69', '70-79',
        '80-89', '90-99', '100+'
    ];
    console.log(male)
    console.log(female)
     Highcharts.chart('p-pyramid', {

         chart: {
             height: 397,
             type: 'bar'
         },
         title: {
             text: 'Population pyramid'
         },
         credits: {
             enabled: false
         },
         subtitle: {
             text: ''
         },
         accessibility: {
             point: {
                 descriptionFormatter: function (point) {
                     var index = point.index + 1,
                         category = point.category,
                         val = Math.abs(point.y),
                         series = point.series.name;
                     return index + ', Age ' + category + ', ' + val + '%. ' + series + '.';
                 }
             }
         },
         xAxis: [{
             categories: categories,
             reversed: false,
             labels: {
                 step: 1
             },
             accessibility: {
                 description: 'Age (male)'
             }
         }, { // mirror axis on right side
             opposite: true,
             reversed: false,
             categories: categories,
             linkedTo: 0,
             labels: {
                 step: 1
             },
             accessibility: {
                 description: 'Age (female)'
             }
         }],
         yAxis: {
             title: {
                 text: null
             },
             labels: {
                 formatter: function () {
                     return Math.abs(this.value);
                 }
             },
             accessibility: {
                 description: 'Percentage population',
                 rangeDescription: 'Range: 0 to 5%'
             }
         },

         plotOptions: {
             series: {
                 stacking: 'normal'
             },
             size:{

             }

         },
         colors: ['#4099ff', '#fd5d93'],
         tooltip: {
             formatter: function () {
                 return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
                     'Population: ' + Math.abs(this.point.y);
             }
         },

         series: [{
             name: 'Male',
             data: male
         }, {
             name: 'Female',
             data: female
         }]
     });
 }
window.semipie = function semipie(data,pieid) {
    Highcharts.chart(pieid, {
        chart: {
            height: 400,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            events: {
                load: function () {
                    var data = this.series[0].data,
                        newData = [];
                    data.forEach(function (point) {
                        newData.push({
                            y: point.y,
                            name: point.name,
                            color: point.color,
                            legendIndex: point.legendIndex
                        })
                    });
                    newData.sort(function (a, b) {
                        return a.y - b.y;
                    });

                    this.series[0].setData(newData);
                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: 'Total count<br>',
            pointFormat: '{point.name}:<b>{point.y}({point.percentage:.1f}%)</b>'
        },
        legend: {
            labelFormat: '<b>{name}:  </b>{y},  ({percentage:.1f} %)'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                showInLegend: true,
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '110%'
            }
        },
        series: [{

            name: 'Total people',
            colorByPoint: true,
            innerSize: '50%',
            data: data
        }]
    });
}
window.pie =  function pie(data,pieid) {
    Highcharts.chart(pieid, {
        chart: {
            height: 400,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            events: {
                load: function () {
                    var data = this.series[0].data,
                        newData = [];
                    data.forEach(function (point) {
                        newData.push({
                            y: point.y,
                            name: point.name,
                            color: point.color,
                            legendIndex: point.legendIndex
                        })
                    });
                    newData.sort(function (a, b) {
                        return a.y - b.y;
                    });

                    this.series[0].setData(newData);
                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: 'Total count<br>',
            pointFormat: '{point.name}:<b>{point.y}({point.percentage:.1f}%)</b>'
        },
        legend: {
            labelFormat: '<b>{name}:  </b>{y},  ({percentage:.1f} %)'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        series: [{

            name: 'Total people',
            colorByPoint: true,
            data: data
        }]
    });
}
window.column = function column(data,columnid,clr,sort = 'y') {
    thousandsp();

    var chart = Highcharts.chart(columnid, {
        chart: {
            type: 'column',

            height: 400

        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            title: {
                text: ''
            },

            type: 'category',
            min: 0,
            labels: {
                animate: true
            }
        },
        tooltip: {
            formatter: function () {

                return '</b>' + this.point.name + '</b><br><b>' + Highcharts.numberFormat(this.y, 0) + '</b>';
            }
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            column:{
                colors: clr
            },
            lang: {
                thousandsSep: ','
            },
            series: {
                colorByPoint: true
            }
        },
        series: [

            {
                zoneAxis: 'x',
                zones: [{
                    value: 1,

                }],

                dataSorting: {
                    enabled: true,
                    sortKey: sort
                },
                data: data
            }],


    });
}
// function map(data) {
//     Highcharts.getJSON('/rajasthan_district.json', function (geojson) {
//
//             // Initiate the chart
//             Highcharts.mapChart('homemap', {
//                 chart: {
//                     height: 500,
//                     map: geojson
//                 },
//
//                 title: {
//                     text: ''
//                 },
//                 credits: {
//                     enabled: false
//                 },
//                 mapNavigation: {
//                     enabled: true,
//                     buttonOptions: {
//                         verticalAlign: 'bottom'
//                     }
//                 },
//                 tooltip: {
//                     formatter: function () {
//                         return '<b>' + this.series.name + '</b><br>' +
//                             '' + this.point.properties['district'] +
//                             ':' + this.point.value;
//                     }
//                 },
//
//                 colorAxis: {
//                     min: 0,
//                     minColor: '#deb7b4',
//                     maxColor: '#ca3f32'
//
//                 },
//                 series: [{
//                     data: data,
//                     keys: ['district', 'value'],
//                     joinBy: 'district',
//                     name: 'Positive',
//                     minSize: 80,
//                     states: {
//                         hover: {
//                             color: 'red'
//                         }
//                     },
//                     dataLabels: {
//                         enabled: true,
//                         formatter: function () {
//                             return this.point.properties['district'];
//                         },
//                     }
//                 }]
//             });
//         }
//     );
// }

